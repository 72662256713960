@use '../../assets/styles/global.scss' as *;

.header {
    position: fixed;
    bottom: 0;
    left: 0;
    
    height: 4rem;
    width: 100%;
    background-color: $white;
    z-index: 999;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        position: relative;
        z-index: 20;
        background-color: $white;
        border-top: 2px solid $black;
    }

    &__logo {
        padding: 1rem;
        font-size: 1.8rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        text-decoration: none;
        font-weight: bold;
        color: $black;
        transition: 0.2s;

        @include tablet {
            padding: 2rem;
        }
    }

    &__logo:hover {
        color: black;
    }

    &__hamburger {
        padding: 1rem;
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 999;
        display: block;
        gap: 6px;
        width: 4rem;
        cursor: pointer;

        background: none;
        border: none;
        outline: none;
        transition: 0.2s;
        @include tablet {
            padding-right: 2rem;
            width: 5rem;
        }
        @include desktop {
            display: none;
        }
    }


    &__bar {
        content: '';
        display: block;
        width: 100%;
        height: 5px;
        background-color: $black;
        margin: 6px 0;
        transition: 0.2s;
    }

    &__bar:before,
    &__bar:after {
        content: '';
        display: block;
        width: 100%;
        height: 5px;
        background-color: $black;
        margin: 6px 0;
        transition: 0.2s;
    }

    &__bar:before {
       position: relative;
       bottom: 11px;
    }

    &__hamburger--active &__bar:before {
        transform: rotate(-45deg) translate(-8px, 6px);
    }

    &__hamburger--active &__bar:after {
        transform: rotate(45deg) translate(-10px, -8px);
    }

    &__hamburger--active &__bar {
        background-color: $white;
    }

    &__menu {
        position: absolute;
        bottom: 4rem;
        list-style: none;
        width: 100%;
        display: flex;
        flex-direction: column;

        z-index: 10;

        height: 12rem;

        transform: translateY(100%);
        transition: 0.2s;

        @include desktop {
            position: relative;
            z-index: 9999;
            transform: translateY(0);
            flex-direction: row;
            justify-content: flex-end;
            width: fit-content;
            float: right;
        }

        @include wide-desktop {
            width: calc(50% - 1rem);

        }
    }

    &__menu--open {
        transform: translateY(0);
    }

    &__link {
        text-decoration: none;
        display: block;background-color: $white;
        color: $black;
        text-align: right;
        padding: 1rem;
        font-size: 1.2rem;
        border-top: 2px solid $black;
        flex-shrink: 2;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;
        cursor: pointer;
        @include desktop {
            padding: 1rem 3rem;
            border-left: 2px solid $black;
        }
        // @include wide-desktop {
        //     padding: 1rem 5rem;
        // }
    }

    &__li {
        @include wide-desktop {
            width: 33.33333333333333333%;
        }
    }

    &__li:last-of-type {
        @include desktop {
            border-right: 2px solid $black;
        }
    }

    &__link:hover {
        background-color: $black;
        color: $white;
    }

    
}