@use '../../assets/styles/global.scss' as *;

.exp {
    padding: 1rem;
    @include tablet {
        padding: 2rem;
    }
    @include wide-desktop {
        padding: 0;
    }

    &__top {
        @include wide-desktop {
            width: 50%;
            padding: 2rem;
        }
    }

    &__page {

        @include wide-desktop {
            display: flex;
            gap: 2rem;

        }
    }

    &__heading {
        color: $black;
        @include tablet {
            font-size: 3rem;
        }
        @include wide-desktop {
            font-size: 5.5rem;
        }
    }

    &__description {
        color: $black;
        font-size: 1rem;
        font-family: 'Jost', sans-serif;
        margin-bottom: 2rem;
        @include tablet {
            font-size: 1.5rem;
            line-height: 2rem;
        }
        @include wide-desktop {
            font-size: 1.8rem;
            line-height: 1.6;
        }
    }

    &__project {
        text-decoration: none;
        color: $white;
        background-color: $black;
        margin-bottom: 2rem;
        display: block;
        position: relative;
        overflow: hidden;
        @include wide-desktop {
            margin-bottom: 3rem;
        }
    }

    &__project:last-of-type {
        margin-bottom: 4rem;
    }

    &__project-name {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: white;
        @include tablet {
            font-size: 2rem;
        }
    }

    &__project-description {
        @include tablet {
            font-size: 1.5rem;
        }
    }

    &__image {
        width: 100%;
        vertical-align: bottom;
        border: 20px solid $black;
        background-color: $black;
    }

    &__content {
        padding: 1rem;
        position: absolute;
        bottom: 0; 
        background-image: linear-gradient(360deg, black, black, rgba(0, 0, 0, 0));
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        padding-top: 1rem;
        transform: translateY(100%);
        width: 100%;
        @include tablet {
            padding: 2rem;
        }
    }

    &__project:hover &__content {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    &__projects {
        
        @include wide-desktop {
            width: 50%;
            border-left: 2px solid $black;
            padding: 2rem;
            overflow-x: scroll;
            height: 100dvh ;
        }
       

    }
}