@use '../../assets/styles/global.scss' as *;

.connect {
    min-height: 100dvh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
        padding: 2rem;
    }
    @include wide-desktop {
        padding: 0;
        max-height: 100dvh;
    }

    &__form {
        width: 100%;
        margin-bottom: 4rem;
        @include wide-desktop {
            padding-left: 2rem;
            padding-top: 2rem;
            padding-right: 2rem;
        }
    }

    &__title {
        margin-bottom: 1rem;
        color: $black;
        @include tablet {
            font-size: 3rem;
        }
        @include wide-desktop {
            font-size: 5.5rem;
        }
    }

    &__input-div {
        position: relative;
        margin-bottom: 1rem;
    }

    &__input-div--select {
        position: static;
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    &__label {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        transition: 0.2s;
        background-color: $white;
        cursor: text;
        @include tablet {
            font-size: 1.2rem;
        }
    }

    &__label--select {
        position: static;
    }

    &__input:focus ~ &__label,
    &__input:valid ~ &__label{
        transform: translateY(-1rem);
        font-size: 0.8rem;
        padding: 0 0.5rem;
        @include tablet {
            font-size: 1rem;
            transform: translateY(-1.2rem);
        }
    }


    &__input {
        resize: none;
        width: 100%;
        height: 2.5rem;
        display: flex;
        font-size: 1rem;
        padding:0.5rem;
        outline: none;
        border-radius: 0;
        border: 2px solid $black;
        background-color: $white;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        line-height: 1.5rem;
        transition: 0.2s;
        @include tablet {
            height: 3rem;
            font-size: 1.4rem;
        }
    }

    &__input--select, &__option {
        cursor: pointer;
        color: $black;
    }



    &__input--textarea {
        height: initial;
        line-height: 1.5;
    }

    &__button {
        width: 100%;
        height: 2.5rem;
        font-size: 1.2rem;
        cursor: pointer;
        transition: 0.2s;
        @include tablet {
            height: 3rem;
            font-size: 1.4rem;
        }
    }


    &__cta {
        border: 2px solid $black;
        background-color: $black;
        color: $white;
    }

    &__cta:hover {
        background-color: #000;
        color: #fff;
    }

    &__thankyou-div {
        display: none;
        overflow-x: scroll;
        @include wide-desktop {
            display: block;
            width: 100%;
            height: calc(100vh - 4rem);
            border-left: 2px solid $black;

        }
    }

    &__h:first-of-type {
        padding-top: 2rem;
    }

    &__p, &__h {
        padding: 0 2rem ;
    }

    &__p {
        padding-bottom: 2rem;
        font-size: 1.2rem;
        font-family: 'Sometype Mono', monospace;
    }

    &__h {
        font-size: 2rem;
        line-height: 1.2;
        padding-bottom: 0.5rem;
    }

    // &__thankyou {
    //     width: 100%;
    //     height: calc(100vh - 4rem);
    // }

    &__components {
        @include wide-desktop {
            display: flex;
            align-items: flex-start;
        }
    }

}