* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    visibility: hidden;
    opacity: 0;
    font-family: 'Titillium Web', monospace;
    transition: 0.5s;
}

*::selection {
    background-color: #333;
    color: #FAEBD7;
}

.home__running-text-container * {
    visibility: visible;
    opacity: 1;
  }
  

html {
    background-color: #FAEBD7;
    transition: 0s ;
    visibility: visible;
    opacity: 1;
}

// VARIABLES
$black: #333;
$gray: #666;
$white: #FAEBD7;

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&family=Sometype+Mono:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');


// DIMENSIONS
@mixin tablet {
    @media (min-width: 481px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 675px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 845px) {
        @content;
    }
}
