@use '../../assets/styles/global.scss' as *;

.about {
    padding: 1rem;
    @include tablet {
        padding: 2rem;
    }

    @include wide-desktop {
        padding: 0;
    }

    &__page {
        @include wide-desktop {
            display: flex;
            gap: 2rem;
        }
    }

    &__heading {
        color: $black;
        margin-bottom: 0.5rem;
        @include tablet {
            font-size: 3rem;
        }
        @include wide-desktop {
            font-size: 5.5rem;
        }
    }

    &__description {
        line-height: 1.6;
        color: $black;
        font-family: 'Jost', sans-serif;
        margin-bottom: 2rem;
        @include tablet {
            font-size: 1.5rem;
        }
        @include wide-desktop {
            font-size: 1.8rem;
            margin-bottom: 0;
        }
    }

    &__list {
        list-style: none;
        margin-bottom: 2rem;
        @include tablet {
             display: flex;
             gap: 0 4rem;
             flex-wrap: wrap;
        }
        @include wide-desktop {
            margin-top: 2rem;
        }
    }

    &__li {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        color: $black;
        @include tablet {
            font-size: 2rem;
            margin-bottom: 2rem;
        }
        @include wide-desktop {
            font-size: 2.2rem;
        }
    }

    &__image-div {
        position: relative;
        overflow: hidden;
        background-image: url('../../assets/images/headway.png');
        background-position: center;
        background-size: cover;   
        cursor: crosshair; 
        border: 2px solid $black; 
    }

    &__image-div--active {
        overflow: scroll;
    }

    &__image {
        width: 100%;
        border: 2px solid $black;
        vertical-align: bottom;
        opacity: 0;
    }

    &__image-description {
        padding: 1rem;
        position: absolute;
        top: 0;
        background-color: $black;
        color: $white;
        min-height: 100%;
        font-size: 1.1rem;
        line-height: 1.6;
        font-family: 'Sometype Mono', monospace;

        transition: 0.2s;

        transform: translateY(100%);
        visibility: hidden;
        opacity: 0;

        @include tablet {
            font-size: 1.3rem;
            padding: 2rem;
        }
        @include desktop {
            font-size: 1.5rem;
        }
        
    }

    &__image-description--visible {
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
    }

    &__section {
        @include tablet {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        @include wide-desktop {
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 2rem;
            width: 50%;
        }
    }

    &__section-workers {
        @include wide-desktop{
            padding: 2rem;
            border-left: 2px solid $black;
            width: 50%;
            overflow-x: scroll;
            height: 100dvh ;
        }
        
    }

    &__break {
        display: block;
        margin-top: 1rem;
        font-family: 'Sometype Mono', monospace;
    }

    &__content {
        padding: 1rem;
        padding-top: 0.5rem;
    }

    &__member {
        border-bottom: 2px solid $black;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        @include tablet {
            margin-bottom: 4rem;
        }
    }

    &__member:last-of-type {
        margin-bottom: 0;
        @include tablet {
            margin-bottom: 2rem;
        }
        @include wide-desktop {
            margin-bottom: 4rem;
        }
    }

    &__member-description {
        color: $gray;
        @include tablet {
            font-size: 1.5rem;
        }
        @include wide-desktop {
            font-size: 1.2rem;
        }
    }

    &__member-name {
        color: $black;
        @include tablet {
            font-size: 2rem;
        }
    }

    &__top {
        @include wide-desktop {
            display: flex;
            gap: 2rem;
            align-items: flex-start;
            flex-direction: column;
            width: 50%;
            padding: 2rem;
        }
    }
}
