@use '../../assets/styles/global.scss' as *;

.home {
    height: 100vh;
    height: 100dvh;

    &__section {
        height: 90%;
        display: flex;
        flex-direction: column;
        @include desktop {
            flex-direction: row;
            align-items: center;
            width: 100%;
        }
    }

    &__section-text {
        border-top: 2px solid $black;
        display: block;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-size: 2rem;
        font-family: 'Mono',  monospace;
        font-weight: 500;
        color: $black;
        background-color: $white;
        transition: 0.2s;
        flex-shrink: 2;
        @include desktop {
            border-top: none;
            height: 100%;
            padding: 1rem;
            width: 25%;
            border-left: 2px solid $black;
        }
        @include wide-desktop {
            padding: 2rem;
            font-size: 2.5rem;
        }
    }

    &__section-text:last-of-type {
        @include desktop {
            border-right: 2px solid $black;
        }
    }

    &__section-text:hover {
        height: 25%;
        background-color: $black;
        color: $white;
        @include desktop {
            height: 100%;
            width: 30%;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        vertical-align: top;
        @include desktop {
            height: initial;
            border-radius: 14px;
        }
    }

    &__image-div {
        width: 100%;
        height: 40%;
        @include desktop {
            padding: 1rem;
            width: 25%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 2px solid $black;
            flex-shrink: 0;
        }
        @include wide-desktop {
            padding: 2rem;
        }
    }

    &__running-text-container {
        display: flex;
        align-items: center;
        height: 5%;
        overflow: hidden;

        background-color: $white;
        border-top: 2px solid $black;
        border-top: 2px solid $black;
        @include desktop {
            border: 2px solid $black;
        }
      }
      
      &__running-text {
        font-family: 'Mono',  monospace;
        white-space: nowrap; 
        margin-right: 1rem;
      }
    
}