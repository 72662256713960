@use '../../assets/styles/global.scss' as *;

   
   .footer {
            background-color: $black;
            position: relative;
            left: -1rem;
            bottom: -1rem;
            width: calc(100% + 2rem);
            padding: 1rem;
            padding-bottom: 5rem;
            @include tablet {
                left: -2rem;
                bottom: -2rem;
                width: calc(100% + 4rem);
            }
            @include desktop {
                display: none;
            }

        &__media {
            list-style: none;
            display: flex;
            gap: 0.8rem;
        }

        &__media-link {
            padding: 0.5rem;
            font-size: 2.5rem;
            position: relative;
            right: 0.5rem;
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;

            @include tablet {
                font-size: 3rem;
            }
        }

        &__icon {
            color: $white;
            background-color: $black;
            transition: 0.2s;
        }

        &__icon:hover {
            color: white;
        }

        &__contact {
            margin-bottom: 0.5rem;
        }

        &__string {
            color: $white;
            font-size: 0.8rem;
            @include tablet {
                font-size: 1rem;
            }
        }
    }